<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <!-- <h6 class="filter-heading d-none d-lg-block">
              {{ $t("investor.Investor_Filters") }}
            </h6> -->
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <div class="divFilters">
          <b-row>
            <b-col cols="12" class="mx-1">
              <h6 class="filter-heading d-none d-lg-block">
                {{ $t("investor.Investor_Filters") }}
              </h6>

              <b-button
                type="reset"
                variant="outline-secondary"
                @click="reset"
                class="mb-2 resetBtn"
              >
                {{ $t("investor.Reset") }}
              </b-button>
            </b-col>
          </b-row>
          <app-collapse accordion>
            <app-collapse-item
              :title="$t('investor.Investment_Size')"
              class="invesmentSize"
            >
              <!-- invesmentSize Slider -->
              <div class="price-slider">
                <vue-slider
                  v-model="filters.invesmentSize"
                  :min="1000"
                  :max="10000000000"
                  :step="1000"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <label>
                  {{
                    $store.state.locale.locale === "en"
                      ? convertInvesmentSize
                      : arConvertInvesmentSize
                  }}
                </label>
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('investor.Intrested_In')">
              <!-- Categories / Transaction Types Slider -->
              <div class="product-categories">
                <b-form-radio-group
                  v-model="filters.categories"
                  class="categories-radio-group"
                  stacked
                  :options="categories"
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('investor.Investment_Type')">
              <!-- Categories / Transaction Types Slider -->
              <div class="product-categories">
                <b-form-group>
                  <b-form-select
                    id="Investor-Role"
                    v-model="filters.invesmentType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      $store.state.locale.locale === 'en'
                        ? InvestorRoleOption
                        : InvestorRoleOptionArabic
                    "
                    label="text"
                  />
                </b-form-group>
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('investor.Industry')">
              <!-- industry -->
              <div id="app">
                <treeselect
                  v-model="filters.industry"
                  :multiple="true"
                  :label="$t('investor.select')"
                  :options="industries"
                  :normalizer="
                    $store.state.locale.locale == 'ar'
                      ? normalizerArabic
                      : normalizer
                  "
                />
              </div>
            </app-collapse-item>
            <app-collapse-item :title="$t('investor.Location')">
              <!-- Location -->
              <div id="app">
                <treeselect
                  v-model="filters.location"
                  :multiple="true"
                  label="Select location ."
                  :options="countries"
                />
              </div>
            </app-collapse-item>
          </app-collapse>
          <!-- Ratings -->
          <div class="ratings ratings ml-2 pb-2">
            <h6 class="filter-title">
              {{ $t("investor.Ratings") }}
            </h6>
            <div
              v-for="rating in filterOptions.ratings"
              :key="rating.rating"
              class="ratings-list"
            >
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[
                      { 'fill-current': star <= rating.rating },
                      star <= rating.rating ? 'text-warning' : 'text-muted',
                    ]"
                    @click="log(rating.rating)"
                  />
                  <span class="ml-25"> {{ $t("investor.up") }}</span>
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import millify from "millify";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormRadioGroup,
  BLink,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BButton,
    BFormSelect,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    Treeselect,
    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      InvestorRoleOption: [
        { value: "1", text: "Individual Investor / Buyer" },
        { value: "2", text: "Corporate Investor / Buyer" },
        {
          label: "Lender",
          options: [
            { value: "3", text: "Bank" },
            { value: "4", text: "Financial Institution" },
          ],
        },
        {
          label: "Financial Advisor",
          options: [
            { value: "3", text: "Accounting Firm" },
            { value: "4", text: "Business Broker" },
            { value: "5", text: "Financial Consultant" },
            { value: "6", text: "Investment Bank" },
            { value: "7", text: "Law Firm" },
            { value: "8", text: "M&A Advisor" },
            { value: "9", text: "Merchant Bank" },
            { value: "10", text: "Commercial Real Estate Broker" },
          ],
        },
        {
          label: "Fund",
          options: [
            { value: "11", text: "Venture Capital Firm" },
            { value: "12", text: "Private Equity Firm" },
            { value: "13", text: "Family Office" },
            { value: "14", text: "Hedge Fund" },
            { value: "15", text: "Search Fund" },
          ],
        },
      ],
      InvestorRoleOptionArabic: [
        { value: "1", text: "مستثمر/مشتري فردي" },
        { value: "2", text: "مستثمر/مشتري شركة" },
        {
          label: "المُقرض",
          options: [
            { value: "3", text: "مصرف" },
            { value: "4", text: "مؤسسة مالية" },
          ],
        },
        {
          label: "مستشار مالي",
          options: [
            { value: "5", text: "شركة محاسبة" },
            { value: "6", text: "وسيط أعمال" },
            { value: "7", text: "مستشار مالي" },
            { value: "8", text: "بنك استثمار" },
            { value: "9", text: "شركة محاماة" },
            { value: "10", text: "مستشار الاندماج والاستحواذ" },
            { value: "11", text: "بنك تجاري" },
            { value: "12", text: "وسيط عقاري تجاري" },
          ],
        },
        {
          label: "تمويل",
          options: [
            { value: "13", text: "شركة رأس المال الاستثماري" },
            { value: "14", text: "شركة الاسهم الخاصة" },
            { value: "15", text: "مكتب العائلة" },
            { value: "16", text: "صندوق التحوط" },
            { value: "17", text: "صندوق البحث" },
          ],
        },
      ],
    };
  },
  computed: {
    convertInvesmentSize() {
      // `this` points to the component instance
      return `${millify(this.filters.invesmentSize[0], {
        precision: 3,
        decimalSeparator: ",",
        space: true,
      })} - ${millify(this.filters.invesmentSize[1], {
        precision: 3,
        decimalSeparator: ",",
        space: true,
      })}`;
    },
    arConvertInvesmentSize() {
      // `this` points to the component instance
      return `${millify(this.filters.invesmentSize[0], {
        precision: 3,
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ",",
        space: true,
      })} - ${millify(this.filters.invesmentSize[1], {
        precision: 3,
        decimalSeparator: ",",
        units: ["مئة", "الف", "مليون", "مليار", "بليون"],
        space: true,
      })}`;
    },
    calcInterval() {
      // `this` points to the component instance
      return (1000000000 - 10000) / 10000;
    },
    estInterval() {
      // `this` points to the component instance
      return (this.filters.Established[1] - this.filters.Established[0]) / 1;
    },
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      countries: "country/getFilterCountries",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCountries();
  },
  methods: {
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    reset() {
      this.filters.invesmentSize = [1000, 10000000000];
      this.filters.invesmentType = null;
      this.filters.industry = null;
      this.filters.location = null;
      this.filters.q = "";
      this.filters.categories = null;
      this.filters.ratings = null;
    },
    log(id) {
      this.filters.ratings = id;
    },
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
    }),
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
<style>
@media (max-width: 991.98px) {
  [dir] .ecommerce-application .sidebar-left .sidebar .sidebar-shop.show {
    margin-top: 65px;
  }
}
</style>
<style scoped>
.divFilters {
  background-color: #fff;
  padding-top: 20px;
  border: 1px solid #ebe9f1;
  margin-top: 3rem;
}
div.card.invesmentSize {
  border-top: 1px solid #ebe9f1;
}
.resetBtn {
  width: 88%;
}
.divFilters .card {
  border: 0;
  border-bottom: 1px solid #ebe9f1;
}

.divFilters h6 {
  font-weight: 500;
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .divFilters {
    margin-top: 0rem;
  }
}
</style>
